<!--
* @Author: DY
* @Date: 2020-05-27 14:52:13
* @Description: 选择市场信息价格
-->
<template>
    <div class="chooseInformationPrice">
        <div class="table-bg" v-loading="loading">
            <div class="table-header">
                <div class="el_left">
                    <el-button type="primary" icon="el-icon-plus" @click="newlyAdded">
                        新增
                    </el-button>
                </div>
                <div class="el_right">
                    <div class="el_left conditionQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="keyword" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                        </el-input>
                    </div>
                </div>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%"
                height="calc(100% - 0.9rem)"
                @selection-change="handleSelectionChange"
                ref="multipleTable"
                @row-click="selateTable"
            >
                <el-table-column
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    prop="info_price_number"
                    label="编码"
                    width="160"
                >
                </el-table-column>
                <el-table-column
                    prop="info_price_name"
                    label="产品名称"
                    width="160"
                >
                </el-table-column>
                <el-table-column label="规格型号">
                    <el-table-column
                        prop="specification_mode_name"
                        label="名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="product_mark_num"
                        label="产品标号"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="additional_model"
                        label="附加型号"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="coarse_aggregate"
                        label="粗骨料"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="fine_aggregate"
                        label="细骨料"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="pour_mode"
                        label="浇筑方式"
                    >
                    </el-table-column>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <div class="el_right">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-sizes="[20,40,60,80,100]"
                        :page-size="limit"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <footer>
            <el-button type="primary" @click="confirmAdd">
                确认
            </el-button>
            <el-button @click="close">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>

export default {
    name: 'choose-information-price',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 市场信息价格列表数据
            tableData: [],
            // 查询条件
            keyword: '',
            // 数据总条数
            total: 0,
            // 当前页码
            page: 1,
            // 每页显示条目个数
            limit: 20,
            // 已选数据
            selectData: [],
        };
    },
    watch: {},
    computed: {},
    created() {
        // 获取市场信息价格列表数据
        this.getTableData();
    },
    mounted() {},
    methods: {
        // 获取市场信息价格列表数据
        getTableData() {
            this.loading = true;
            this.$axios
                .get('/interfaceApi/sale/info_price/get_warehouse_list?'
                    + `keyword=${this.keyword}`
                    + `&regionalNumber=${this.extr.regionalNumber}`
                    + `&pageindex=${this.page}`
                    + `&pagesize=${this.limit}`)
                .then(res => {
                    this.$set(this, 'tableData', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 点击行选中
        selateTable(row) {
            this.selectData = [];
            this.$refs.multipleTable.toggleRowSelection(row);
            this.selectData = this.$refs.multipleTable.selection;
        },
        // 多选选中
        handleSelectionChange(val) {
            this.selectData = val;
        },
        // 确认添加
        confirmAdd() {
            this.extr.selectCallBack(this.selectData);
            this.$parent.hide();
        },
        // 关闭
        close() {
            this.$parent.hide();
        },
        // 新增
        newlyAdded() {
            this.$toast({
                title: true,
                text: '新增信息价产品',
                type: 'eject',
                width: '13rem',
                height: '8rem',
                t_url: 'salesBusiness/marketInforPrice/newMarketPrice',
                viewPosition: 'view',
                extr: {
                    selectCallBack: this.newlyAddedCallBack,
                    regionalNumber: this.extr.regionalNumber,
                },
            });
        },
        // 新增回调
        newlyAddedCallBack() {
            // 获取市场信息价格列表数据
            this.getTableData();
        },
    },
};
</script>

<style lang="stylus">
.chooseInformationPrice
    padding 0.14rem
    height 100%
    .table-bg
        height calc(100% - 0.7rem)
        .table-header
            overflow hidden
            margin-bottom 0.14rem
            .el_left
                .el-button
                    height 0.32rem
                    padding 0 0.15rem
                    line-height 0.32rem
            .conditionQuery
                width 3.6rem
                .el-input-group__append
                    background #1577d2
                    border 1px solid #1577d2
                    .el-button
                        color #fff
        .el-table
            table
                border-collapse: collapse;
                th,td
                    padding 0.08rem 0
                    border 1px solid #DEDFE0
                &::before
                    background-color #d7d7d7
                thead
                    th
                        background #EDF0F5
                        color #022782
                        font-weight normal
                        font-size .16rem
            .el-table__body-wrapper
                overflow-y auto
        .table-page
            overflow hidden
    footer
        height 0.4rem
        margin-top 0.2rem
        text-align center
        .el-button
            width 2.2rem
            height 0.4rem
            margin 0 0.3rem
</style>
